.App {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
}
.holder {
  cursor: pointer;
}
img {
  max-width: 100%;
  height: auto;
}

.index-btn {
  border: 1px solid #ffffff !important;
  box-shadow: 0 0 4px 2px rgb(42 87 141) !important;
  width: 30px !important;
  height: 30px;
  margin-left: 10px;
  background-color: white;
}
